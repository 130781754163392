import React from 'react'
import styled from 'styled-components'

// Components
import Layout from '../components/Layout'

const NotWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  min-height: 90vh;
`

const NotFound = () => {
  return (
    <>
      <Layout>
        <NotWrapper>Not Found</NotWrapper>
      </Layout>
    </>
  )
}

export default NotFound
