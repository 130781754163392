import React from 'react'
import styled from 'styled-components'

// Components
import Seo from './Seo'
import Header from './Header'
import PageHeader from './Header/PageHeader'
import Curve from './Curve'
import Footer from './Footer'

const LayoutWrapper = styled.div`
  background: ${({ theme }) => theme.colors.ghost};
  min-height: calc(50vh);
`

const LayoutContent = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.md};
  margin-right: auto;
  margin-left: auto;
  padding-top: ${({ theme }) => theme.spacings.large};
  padding-right: ${({ theme }) => theme.spacings.small};
  padding-bottom: ${({ theme }) => theme.spacings.large};
  padding-left: ${({ theme }) => theme.spacings.small};
`

const Layout = ({ title, subTitle, children }) => {
  return (
    <>
      <Seo title={title} />
      <Header />
      <PageHeader title={title} subTitle={subTitle} />
      <Curve />
      <LayoutWrapper>
        <LayoutContent>{children}</LayoutContent>
      </LayoutWrapper>
      <Curve flip />
      <Footer />
    </>
  )
}

export default Layout
