import React from 'react'
import styled from 'styled-components'

const CurveWrapper = styled.div`
  padding: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 110px;
  max-height: 110px;
  transform: ${props => (props.flip === true ? 'rotate(180deg)' : 'none')};
  margin-top: ${props => (props.flip === true ? '-1px' : '0')};
  margin-right: auto;
  margin-bottom: ${props => (props.flip === true ? '0' : '-1px')};
  margin-left: auto;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: ${props => (props.flip === true ? '-1px' : `-48px`)};
    margin-bottom: ${props => (props.flip === true ? '-32px' : `-1px`)};
  }
`

const Box = styled.div`
  display: flex;
  flex: 1;
  background: ${({ theme }) => theme.colors.ghost};
  height: 109px;
  max-height: 109px;
  width: 100%;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
    visibility: hidden;
  }
`

const CurveSVG = styled.svg`
  display: block;
  overflow: hidden;
  text-align: center;
  width: 1920px !important;
  height: 110px !important;
  fill: ${({ theme }) => theme.colors.ghost};
  align-items: center;
  margin: 0 auto;
`

const Curve = ({ flip }) => {
  return (
    <>
      <CurveWrapper flip={flip}>
        <Box />
        <CurveSVG
          x="1920"
          y="110"
          preserveAspectRatio="xMaxYMax"
          viewBox="0 0 1920 110"
          width="1920"
          height="110"
        >
          <path d="M974.3,101.6c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.7,0v0C584.1,101.3,389.3,0.7,0,0.7v109.2l1920,0.1V0.7C1541.6,0.7,1352.2,101.3,974.3,101.6L974.3,101.6z" />
        </CurveSVG>
        <Box />
      </CurveWrapper>
    </>
  )
}

export default Curve
